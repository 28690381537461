import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    getDefaultServiceBanner(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('/cms/get_default_service_banner', { params })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    getListServiceBanner(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('/cms/get_list_service_banner', params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    getServiceBanner(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('/cms/get_service_banner', params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addServiceBanner(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('/cms/add_service_banner', params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    editServiceBanner(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('/cms/edit_service_banner', params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    deleteServiceBanner(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('/cms/delete_service_banner', params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    serviceBannerOptions(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('/cms/service_banner_options', params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    uploadImageServiceBanner(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post(`/cms/upload_image_service_banner/${params.id}`, params.data, {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
